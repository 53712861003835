/*
 * Globals
 */
/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

.cover-container {
  max-width: 60em;
}
html,
body,
#root {
  height: 100% !important;
}
/*
 * Header
 */

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(102, 102, 102, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  border-bottom-color: rgb(118, 246, 104);
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}