@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background: #ffffff;
}

table.table-hover tr {
  cursor: pointer;
}

.pbiReportWrapper, .pbiReportWrapper div {
  height: 100%;
}
