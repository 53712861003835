:root {
  --prm-color: #0381ff;
  --prm-gray: #b1b1b1;
}

/* CSS */
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.step-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: var(--prm-gray);
  transition: .4s;
}

.step-button[aria-expanded="true"] {
  width: 60px;
  height: 60px;
  background-color: var(--prm-color);
  color: #fff;
}

.done {
  background-color: var(--prm-color);
  color: #fff;
}

.step-item {
  z-index: 10;
  text-align: center;
}

.onboardingProgress {
-webkit-appearance:none;
  position: absolute;
  width: 100%;
  z-index: 5;
  height: 10px;
  margin-bottom: 18px;
}

/* to customize progress bar */
.onboardingProgress::-webkit-progress-value {
  background-color: var(--prm-color);
  transition: .5s ease;
}

.onboardingProgress::-webkit-progress-bar {
  background-color: var(--prm-gray);
}