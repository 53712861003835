
#dashboard {
  height: 100vh;
  display: flex;
}
#dashboard main {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
#dashboard .closemenu {
  color: rgb(128, 128, 128);
  line-height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}
.pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #444444;
}
#dashboard .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  padding: 0px 20px;
  color: #000;
  font-weight: bold;
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #dfdfdf;
  color: #000;
  border-radius: 3px;
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}
#dashboard .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #bababa;
}
#dashboard .logo {
  padding: 20px;
}
#dashboard .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px 8px 20px;
  cursor: pointer;
}